.intimate-zone-under button,
.intimate-zone-under .row {
  margin-bottom: 2em;
}

.intimate-zone-over {
  margin-top: 2em;
}

.winnerlist {
  font-size: 3em;
}

.reset {
  position: fixed;
  top: 5px;
  right: 0px;
  height: 20px;
  width: 60px;
}
